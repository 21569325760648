
























import Vue from "vue";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError } from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
export default Vue.extend({
  name: "btn-edit-motive",
  props: {
    item: Object,
  },
  data() {
    return {
      DialogEdit: false,
      rules,
      titlenom: "Motive",
      nameitem: "",
      idItem: 0,
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.idItem = this.item.id;
    this.nameitem = this.item.name;
  },
  methods: {
    ...mapMutations("crmSettingsModule", ["mutMotives"]),
    ...mapActions("crmSettingsModule", ["actUpdateNomenclator"]),
    cancel() {
      this.DialogEdit = false;
    },
    async listMotives() {
      const motiv = (await getAPI(`/sprocedures/listNom/ReasonOff`)).data;
      this.mutMotives(motiv);
    },

    update(): void {
      const body = { name: this.nameitem, type: "ReasonOff", id: this.idItem };
      this.actUpdateNomenclator(body)
        .then(() => {
          this.listMotives();
          this.cancel();
          notifySuccess(`The Motive has been updated`);
        })
        .catch((error: AxiosError) => {
          this.loading = false;
          this.cancel();
          notifyError(
            error.response?.data.message || error.response?.data.details
          );
        });
    },
  },
});
