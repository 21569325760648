





















































































import Vue from "vue";

import BtnRemoveNomenclator from "@/views/Settings/buttons/BtnRemoveNomenclator.vue";

import { mapActions, mapState, mapMutations } from "vuex";
import { getAPI } from "@/api/axios-base";
import BtnEditMotive from "../buttons/BtnEditMotive.vue";
import BtnCreateMotive from "../buttons/BtnCreateMotive.vue";

export default Vue.extend({
  components: { BtnRemoveNomenclator, BtnEditMotive, BtnCreateMotive },
  name: "motives",
  data() {
    return {
      search: "",
      name: "",
      uuid: "",
      dialogConfirmRemove: false,
      itemTemp: {},
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loading", "motives"]),
  },
  mounted() {
    this.listMotives();
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actUpdateNomenclator"]),
    ...mapMutations("crmSettingsModule", ["mutMotives"]),

    async listMotives() {
      const motivs = (await getAPI(`/sprocedures/listNom/ReasonOff`)).data;
      this.mutMotives(motivs);
    },
  },
});
